import * as React from 'react';


export default function PricesAndBenefits() {

    return (
        <section className="pt-24 pb-32 overflow-hidden bg-gray-50"><div className="container mx-auto px-4">
            <div className="max-w-8xl mx-auto mb-16">
            <h2 className="font-heading font-bold text-6xl sm:text-7xl text-[#264653] text-center mt-12">Pricing</h2>
            <div className="max-w-3xl m-auto border-b border-b-gray-300 p-4 mb-8" />
            <h5 className="font-heading font-bold text-5xl sm:text-4xl text-[#2a9d8f] text-center">Choose the package that's right for you</h5>
            </div>
            <small className="text-gray-500 max-w-7xl block mb-4 mx-auto">* These are early days prices. Get in touch to lock in your position.</small>
            <div className="flex flex-wrap max-w-7xl mx-auto -m-3">

            <div className="w-full md:w-1/3 p-3">
                <div className="mb-9 p-9 bg-white text-center border border-[#2a9d8f] rounded min-h-[30rem]">
                <div className="mb-11 p-px bg-gradient-cyan max-w-max mx-auto rounded-full">
                </div>
                <h3 className="font-heading font-bold sm:text-4xl text-5xl text-[#264653]" >Single Page Site<div>£449</div>
                </h3>
                <p className="text-justify mt-12 mb-6 text-gray-500 item-description" >What I like to call a 'Show and tell' website. Tell you audience what your about. No nonsence marketing</p>
                </div>
                <div className="px-7">
                <ul>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]" >Fully Responsive Layout</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]" >Included contact form</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]" >Ongoing support</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]" >Included contact form</p>
                    </li>
                </ul>
                </div>
            </div>
            <div className="w-full md:w-1/3 p-3">
                <div className="bg-white mb-9 p-9 text-center border border-[#2a9d8f] rounded min-h-[30rem]">
                <div className="mb-11 p-px bg-gradient-cyan max-w-max mx-auto rounded-full">
                </div>
                <h3 className="font-heading font-bold sm:text-4xl text-5xl text-[#264653]">Dynamic Site
                    £1099</h3>
                <p className="text-justify mt-12 mb-6 text-gray-500 item-description">Do you need your site to update as your content updates?&nbsp;</p> 
                <p className="text-gray-500 item-description text-justify">If you want to use an established CMS to manage your content, be it a blog, portfolio cataloge, or event listings, but don't want to compromise on design flexability and performance, this is the option for you.&nbsp;&nbsp;</p>
                </div>
                <div className="px-7">
                <p className="mb-6 font-heading font-semibold text-gray-500">Everything in a Single Page Site, Plus:</p>
                <ul>
                    {/* <li className="mb-5 flex items-center font-heading font-semibold text-lg"> */}
                    {/* <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Fully Responsive Layout</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Included Contact Form</p>
                    </li> */}
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Automatic content updates</p>
                    </li>
                    <li className="flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Included CMS setup if needed</p>
                    </li>
                </ul>
                </div>
            </div>
            <div className="w-full md:w-1/3 p-3">
                <div className="bg-white mb-9 p-9 text-center border border-[#2a9d8f] rounded min-h-[30rem]">
                <div className="mb-11 p-px bg-gradient-cyan max-w-max mx-auto rounded-full">
                </div>
                <h3 className="font-heading font-bold sm:text-4xl text-5xl text-[#264653]">E-Commerce Comming Soon</h3>
                <p className="text-justify mt-12 mb-6 text-gray-500 item-description">Use E-commerce platforms such as Shopify or BigCommerce as your 'source of truth' for data and functionality And let Salient Applications Intergrate all of your data into a ligning fast storefront.</p>
                <p className="text-justify mt-12 mb-6 text-gray-500 item-description">Watch this space</p>
                </div>
                <div className="px-7">
                {/* <p className="mb-6 font-heading font-semibold text-gray-500">Everything in Pro, Plus:</p> */}
                <ul>
                    {/* <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Fully Responsive Layout</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Included Contact Form</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Ongoing Support</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Included CMS setup if needed</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Included Store Setup if needed</p>
                    </li>
                    <li className="mb-5 flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Download images</p>
                    </li>
                    <li className="flex items-center font-heading font-semibold text-lg">
                    <svg className="mr-2.5" width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M19.2134 6.08683C19.6625 6.53593 19.6625 7.26407 19.2134 7.71317L10.0134 16.9132C9.56426 17.3623 8.83613 17.3623 8.38702 16.9132L3.78702 12.3132C3.33792 11.8641 3.33792 11.1359 3.78702 10.6868C4.23613 10.2377 4.96427 10.2377 5.41337 10.6868L9.20019 14.4737L17.587 6.08683C18.0361 5.63772 18.7643 5.63772 19.2134 6.08683Z" fill="#14B082" /></svg><p className="text-[#264653]">Free Custom Domain</p>
                    </li> */}
                </ul>
                </div>
            </div>
            </div>
        </div>
        </section>

    )
}