import * as React from 'react';
import { Link } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image';


export default function SomthingDifferent() {


    return (
        <section className="py-36 overflow-hidden">
            <div className="container mx-auto px-4">
                <div className="bg-gray-50 shadow-9xl mx-auto max-w-7xl">
                    <div className="grid grid-cols-1 border border-[#2a9d8f] lg:grid-cols-3">
                        <div className="col-span-2 lg:flex-1 px-12 py-14 lg:px-24 lg:py-28">
                        <h2 className="mb-12 font-heading font-bold text-6xl sm:text-8xl xl:text-8xl text-[#264653] text-justify lg:text-left">Need Something Special?&nbsp;</h2>
                        <p className="mb-9 lg:max-w-lg text-gray-600 text-xl" >Every project has different challenges. Need to discuss further how yours can be brought to life?</p>
                        <p className="mb-9 lg:max-w-lg text-gray-600 text-xl font-semibold">Get in touch</p>
                        
                        <Link to="/contact/">
                            <button className="group relative font-heading py-4 px-6 block w-full md:w-auto text-base text-white font-medium bg-[#e76f51] overflow-hidden rounded-lg">
                                <div className="absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-[#264653]" />
                                <p className="relative">Contact Now</p>
                            </button>
                        </Link>
                        </div>
                        <div className="col-span-1 lg:w-auto ml-auto">
                            <StaticImage objectFit='cover' src='../../../images/telephone.jpg' className="mx-auto lg:mr-0 lg:ml-auto h-full object-cover" alt="tel" />
                            {/* <img className="mx-auto lg:mr-0 lg:ml-auto h-full object-cover" src="https://images.unsplash.com/photo-1534536281715-e28d76689b4d?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwzMzIzMzB8MHwxfHNlYXJjaHwxfHxjb250YWN0fGVufDB8fHx8MTY2MDMwMDgyMA&ixlib=rb-1.2.1&q=80&w=1920" alt="dfsv" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}